/* Base styles */
.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Video Background */
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Navigation */
.nav-bar {
  position: fixed;
  top: 0;
  width: 98%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #00f7ff;
}

.buy-button {
  background: linear-gradient(45deg, #00f7ff, #00a8ff);
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(0, 247, 255, 0.3);
}

.buy-button:hover {
  background: linear-gradient(45deg, #00a8ff, #00f7ff);
  box-shadow: 0 6px 20px rgba(0, 247, 255, 0.4);
}

/* Image Display */
.image-display {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.flashing-image {
  width: 80%;
  max-width: 600px;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 247, 255, 0.2);
  animation: flash 1s infinite;
}

@keyframes flash {
  0%, 100% { 
    opacity: 1;
    transform: scale(1);
  }
  50% { 
    opacity: 0.8;
    transform: scale(0.98);
  }
}

/* Audio Button */
.toggle-audio-button {
  position: fixed;
  top: 20px;
  left: 20px;
  padding: 0.5rem 1rem;
  background: #00a8ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease;
}

.toggle-audio-button:hover {
  background: #007acc;
}

/* Copyable Text */
.copy-text {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease;
}

.copy-text:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-bar {
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    font-size: 1.2rem;
  }

  .flashing-image {
    width: 90%;
    border-radius: 15px;
  }
}